@tailwind base;
/* @tailwind components; */
@tailwind utilities;

@layer utilities {
    .word-space-sm {
        word-spacing: 0.1em;
    }
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
    font-family: 'Poppins', sans-serif !important;
}

.fill-mode-forwards {
    animation-fill-mode: forwards;
}

.ant-btn-primary:not([disabled]) {
    background-color: #1677ff;
}

/* Without flex and align, icons in ant buttons are not centered. Hence the override. */
.ant-btn-icon-only {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* --------- ANTD OVERIDES START --------- */
.ant-steps-item-icon {
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
}

.striped-rows:hover td {
    background-color: inherit !important;
}

.ant-popover-p-0 .ant-popover-inner {
    padding: 0;
}

/* --------- ANTD OVERIDES END ----------- */

.achievements-bg {
    background: linear-gradient(180deg, rgba(248, 149, 0, 0) 0%, #FFB8A9 100%);
}

.achievements-shadow {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

::-moz-selection {
    color: inherit !important;
    background: #fef08a !important;
}

::selection {
    color: inherit !important;
    background: #fef08a !important;
}

/* total width */
*::-webkit-scrollbar {
    background-color: transparent;
    width: 14px;
}

/* background of the scrollbar except button or resizer */
*::-webkit-scrollbar-track {
    background-color: transparent;
}

/* scrollbar itself */
*::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 16px;
    border: none;
}

*:hover::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border: 4px solid rgb(248 250 252 / 1);
}

/* set button(top and bottom of the scrollbar) */
*::-webkit-scrollbar-button {
    display: none;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

/* Audio Player CSS */
.rhap_container {
    background-color: rgb(241 245 249) !important;
    border-radius: 8px !important;
}

.rhap_volume-controls {
    justify-content: flex-start !important;
    flex: 0.25 0 auto !important;
}

.rhap_time {
    font-size: 12px !important;
}

.rhap_volume-button {
    font-size: 16px !important;
    margin-right: -3px !important;
}

.rhap_progress-bar {
    height: 2px !important;
}

.rhap_progress-indicator {
    width: 8px !important;
    height: 8px !important;
    margin-left: 0px !important;
    top: -3px !important;
}

.rhap_volume-bar {
    height: 2px !important;
}

.rhap_volume-indicator {
    width: 8px !important;
    height: 8px !important;
    margin-left: -6px !important;
    top: -3px !important;
}

.rhap_play-pause-button {
    font-size: 30px !important;
}

.overall-report {
    margin-left: auto;
}

.only-print {
    display: none;
}
/* create passage */
.passage-textbox {
    width: 100%;
    height: 200px;
    padding: 8px;
    border: 1px solid #ccc;
    font-size: 14px;
    resize: vertical;
  }
  .wide-tall-dropdown {
    width: 100%;
    height: 30px;
    background: #fdfcfc;
    border: 1px solid #ccc;
  }
  
  .true-false-options {
    margin-top: 10px;
    display: flex;
    align-items: center;
  }
  
  .answer-options {
    display: flex;
    align-items: center;
  }
  
  .true-button {
    margin-right: 10px;
    margin-left: 10px;
  }
  
  .answer-options label {
    margin-right: 5px;
  }
  
  .radio-button-container {
    display: flex;
    justify-content: space-evenly; 
  }
  /* Update button */
  .popup {
    position: fixed;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999; 
    transition: left 0.3s ease-in-out; 
  }
  
  .popup.open {
    left: 0; 
  }
  
  .dangerB {
    background-color: red;
    border-color: red;
    color: white;
  }
  .greenB {
    background-color: green;
    border-color: green;
    color: white;
  }
  
  /* CSS for the modal container */
  .modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5); 
    z-index: 1000; 
  }
  
  /* CSS for the modal content */
  .modal-content {
    background-color: white;
    padding: 60px;
    border-radius: 20px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    text-align: center;
    font-size: large;
  }
  
  /* CSS for buttons */
  .modal-buttons {
    margin-top: 10px;
  }
  
  /* CSS for the header (Confirm Deletion) */
  .modal-header {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 20px;
    color: red;
  }
  .error-message {
    color: red;
    font-size: 14px;
    margin-top: 5px;
  }
  
  .form-container {
    display: flow-root;
    align-items: center;
    padding: 60px;
  }
  .update-form-container {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    padding: 20px;
    z-index: 999;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    margin-top: 20px;
  }
  /* speechscripts */
  .script-label {
    margin-bottom: 1rem;
  }
  /* harsha  */
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    z-index: 1000;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .option-container {
    display: flex;
  
    align-items: center;
  
    margin-bottom: 10px;
  }
  
  .option-container p {
    margin-right: 10px;
  }
  
  .option-container input[type="text"] {
    flex-grow: 1;
  
    max-width: 70%;
  
    padding: 8px;
  
    border: 1px solid #ccc;
  
    border-radius: 4px;
  }
  
  .option-container .ant-radio-wrapper {
    margin-left: 10px;
  }

   /* user activity */
   .usrcertlvl-count {
    border-bottom: 1px solid #e0e0e0;
    padding: 2px 0;
    margin: 0px 15px;
    font-weight: bold;
}
.usrcertlvl-1-count{
    color: rgb(103, 58, 183);
    vertical-align: top;
    margin-right: 5px;
}
.usrcertlvl-2-count{
    color: rgb(12, 211, 211);
    vertical-align: top;
    margin-right: 5px;
}
.usrcertlvl-3-count{
    color: rgb(236, 204, 19);
    vertical-align: top;
    margin-right: 5px;
}
.usrcertlvl-4-count{
    color: rgb(11, 228, 120);
    vertical-align: top;
    margin-right: 5px;
}
.usrcertlvl-5-count{
    color: rgb(0, 24, 240);
    vertical-align: top;
    margin-right: 5px;
}
.usrcertlvl-6-count{
    color: rgb(110, 63, 9);
    vertical-align: top;
    margin-right: 5px;
}
.usrcertlvl-7-count{
    color: rgb(34, 105, 6);
    vertical-align: top;
    margin-right: 5px;
}
.level-info {
  text-align: left;
  display: flex;
  align-items: center;
}

.count-info {
  text-align: right;
}

/* invoice */
.invoice-wrapper {
  font-size: 15px;
  background: #f5f4f4;
  padding: 50px 0;
}
.invoice-main {
  width: 70%;
  margin:0 auto;
  background: white;
  padding: 2%;
border-radius: 10px;
}
.vertical-middle {
  display: inline-block;
  vertical-align: middle;
}
.float-right {
  float: right;
  margin-bottom: 25px;
}
.align-right {
  text-align: right;
}
.align-center {
  text-align: center;
}
.divider {
  border-bottom: 1px solid #d0d0d0;
  padding-bottom: 25px;
  margin-bottom: 25px;
}
.logo-main img {
height: 35px;
}
.row {
  display: inline-table;
width: 100%;
}
.invoice-heading {
text-align: center;
}
.invoice-heading h3 {
margin-top: 0px;
}
table.item-desc-table {
  width: 100%;
}
table.item-desc-table th {
text-align: left;
padding-right: 5px;
line-height: 25px;
}
.tbl-item-desc {
width: 55%;
}
.tbl-th-2 {
width: 10%;
text-align: right !important;
}
.tbl-th-3 {
width: 10%;
text-align: right !important;
}
.empty-divider {
  height: 20px;
}
.underline {
  border-bottom: 1px solid #d0d0d0;
}
.item-tr {
background: #f7f6f6;
}
.item-tr td {
  padding: 5px 5px;
}
.gst-tr td {
  padding-bottom: 5px;
}
.total-tr td {
  padding-top: 5px;
}
.box-div {
text-align: center;
border: 1px solid #d0d0d0;
padding: 20px 0;
font-size: 12px;
margin-bottom: 100px;
}
@media only screen and (max-width: 1500px) {
  .invoice-wrapper {
          font-size: 14px;
        

  }
  .invoice-main {
          width: 100%;

       
  }
  .box-div {
      font-size: 10px;
  }
  .logo-main img {
      height: 25px;
  }
}
@media only screen and (max-width: 1200px)  {
  .invoice-main {
          width: 100%;
  }
}
/* // payment option  */
.payment-options-container {
  display: inline-block;
  margin: 10px 0;
  background-color: rgb(241, 245, 249, 0.8);
  border-radius: 10px;
  padding: 10px;
}

/* blur bg for swayam user  */
.blur {
  filter: blur(0.7px); 
}

.modal-phone-input-form {
  width: 100% !important;
  border-radius: 4px !important;
  border: 1px solid #ccc !important;
}

.btn-transparent {
  color: #1677ff;
  border-color: #1677ff;
}

.div-form-disabled {
  pointer-events: none;
  opacity: 0.7;
}

.wrap-div {
  word-break: break-all;
  word-wrap: break-word;
}

.unordered-list {
  list-style: disc !important;
}

.font-rupee {
  font-family: 'Rupee', sans-serif;
}